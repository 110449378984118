import { createSlice } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'

const initialState = () => {
  return {
    invoiceNumber: 10,
  }
}

const subscriptionsSlice = createSlice({
  name: 'subscriptionsSlice',
  initialState: initialState(),
  reducers: {
    increaseInvoiceNumber(state) {
      state.invoiceNumber += 1;
    },
  },
});

export const { increaseInvoiceNumber } = subscriptionsSlice.actions

export default persistReducer(
  { key: 'subscriptionsSlice', storage, whitelist: ['invoiceNumber'] },
  subscriptionsSlice.reducer
);
