import React from 'react'
import '../../../../i18n'
import './needHelp.css'
import 'antd/dist/antd.css'
import { useTranslation } from 'react-i18next'
import chat from "../../../../assets/icon/chat.svg"
import comunity from "../../../../assets/icon/comunity.svg"
import mail from "../../../../assets/icon/mail.svg"
import { Link } from 'react-router-dom'

function Mailto({ email, ...props }) {
  return (
    <a href={`mailto:${email}`} className='item-info-chat-link'>
      {props.children}
    </a>
  );
}


function NeedHelp(props) {
  const { t } = useTranslation()
  return (
    <div className='need-help-container'>
      <div className='need-help-container-title'>
        <h2 className='title-help'>{t("LandingPartners.needHelp")}</h2>
      </div>
      <div className='need-help-item-container'>
        <div className='need-help-item'>
          <div className='item-pic'>
            <img src={chat} alt="chat" className='need-help-pic'/>
          </div>
          <div className='item-info'>
            <h3 className='item-info-title'>
            {t("LandingPartners.needHelpChat")}
            </h3>
            <p className='item-info-description'>
            {t("LandingPartners.needHelpChatDescription1")} <br />
            {t("LandingPartners.needHelpChatDescription2")}
            </p>
            <span className='item-info-chat-link'>
            {t("LandingPartners.needHelpChatLink")}
            </span>
          </div>
        </div>
        <div className='need-help-item'>
          <div className='item-pic'>
            <img src={mail} alt="mail" className='need-help-pic'/>
          </div>
          <div className='item-info'>
            <h3 className='item-info-title'>
            {t("LandingPartners.needHelpEmail")}
            </h3>
            <p className='item-info-description'>
            {t("LandingPartners.needHelpEmailDescription1")} <br />
            {t("LandingPartners.needHelpEmailDescription2")}
            </p>
            <span className='item-info-chat-link'>
            <Mailto email="my@tonna.io">
              {t("LandingPartners.needHelpEmailLink")}
            </Mailto>
            </span>
          </div>
        </div>
        <div className='need-help-item'>
          <div className='item-pic'>
            <img src={comunity} alt="comunity" className='need-help-pic'/>
          </div>
          <div className='item-info'>
            <h3 className='item-info-title'>
            {t("LandingPartners.needHelpCommunity")}
            </h3>
            <p className='item-info-description'>
            {t("LandingPartners.needHelpCommunityDescription1")} <br />
            {t("LandingPartners.needHelpCommunityDescription2")}
            </p>
            <span className='item-info-chat-link'>
            <Link exact to={{ pathname: "https://vk.com/tonnaio" }} target="_blank" className='item-info-chat-link'>
              {t("LandingPartners.needHelpCommunityLink")}
            </Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NeedHelp
