import React, { useEffect, useCallback } from 'react'
import '../../../i18n'
import './header.css'
import 'antd/dist/antd.css'
import { Link, NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// import { ReactComponent as MenuSVG } from '../../../assets/icon/menu.svg';
// import { ReactComponent as AvatarSVG } from '../../../assets/icon/avatar.svg';
import { ReactComponent as DocumentSVG } from '../../../assets/icon/document.svg';
import { ReactComponent as HomeSVG } from '../../../assets/icon/home.svg';
import { ReactComponent as MapSVG } from '../../../assets/icon/map.svg'
import { ReactComponent as WalletSVG } from '../../../assets/icon/wallet.svg'
import { ReactComponent as SubscriptionsSVG } from '../../../assets/icon/crown.svg'
import { ReactComponent as NewOfferSVG } from '../../../assets/icon/plus.svg'
import { ReactComponent as PartnersSVG } from '../../../assets/icon/partners.svg'
import { Tooltip } from 'antd'


function Header(props) {
  const { i18n } = useTranslation()
  const { t } = useTranslation()
  const changeLang = useCallback((e) => {
    i18n.changeLanguage(e.target.value)
  })
  useEffect(() => {
    document.title = t('docTitle')
  },[changeLang, t])
  return (
    <header className="header">

      <div className='header_wrapper'>
        <Link to={{ pathname: "https://tonna.io/" }} target="_blank"><div className="logo">TONNA<sup>&reg;</sup></div></Link>
        <div className='header-nav'>
          <select name="lang" id="lang" value={i18n.language} onChange={changeLang}>
            <option value="ru">Ru</option>
            <option value="en">En</option>
          </select>
          <div className='header-nav_desktop'>
            <Tooltip title={t("Menu.partners")}>
              <NavLink className='item' activeClassName='menu_item-active' exact to='/'>
                <PartnersSVG style={{width: '24px', height: '22px'}} className='menu-svg' />
              </NavLink>
            </Tooltip>
            <Tooltip title={t("Menu.subscriptions")}>
              <Link className='item' activeClassName='menu_item-active' exact to={{ pathname: "https://tonna.io/subscriptions" }} target="_blank">
                <SubscriptionsSVG style={{width: '20px', height: '18px'}} className='menu-svg' />
              </Link>
            </Tooltip>
            <Tooltip title={t("Menu.newRequest")}>
              <Link className='item' activeClassName='menu_item-active' exact to={{ pathname: "http://tonna.io/newoffer" }} target="_blank">
                <NewOfferSVG style={{width: '22px', height: '25px'}} className='offer-svg' />
              </Link>
            </Tooltip>
            <Tooltip title={t("Menu.home")}>
              <Link className='item' activeClassName='menu_item-active' exact to={{ pathname: "http://tonna.io" }} target="_blank">
                <HomeSVG className='menu-svg' />
              </Link>
            </Tooltip>
            <Tooltip title={t("Menu.requests")}>
              <Link className='item' activeClassName='menu_item-active' to={{ pathname: "http://tonna.io/offers/" }} target="_blank">
                <DocumentSVG className='menu-svg' />
              </Link>
            </Tooltip >
            <Tooltip title={t("Menu.map")}>
              <Link className='item' activeClassName='menu_item-active' to={{ pathname: "http://tonna.io/map" }} target="_blank">
                <MapSVG className='menu-svg' />
              </Link>
            </Tooltip>
            <Tooltip placement='bottomLeft' title={t("Menu.wallet")}>
              <Link className='item' activeClassName='menu_item-active' to={{ pathname: "http://tonna.io/wallet" }} target="_blank">
                <WalletSVG className='menu-svg' />
              </Link>
            </Tooltip>
            {/* <a className='item'>
              <MenuSVG className='menu-svg' />
              <div className='sub-menu'>
                <span className='sub-menu-item'>{t('Applications.waiting')} <div className='circle'></div></span>
                <span className='sub-menu-item'>{t('Applications.applicationManagement')} <div className='circle'></div></span>
              </div>
            </a> */}
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
