import React from 'react';
import moment from 'moment'
import {stringToNum} from "../../masks";

const rubles = require('rubles').rubles

const ProPayment = (props) => {
    const formatPrice = stringToNum(91200)
    const textPrice = rubles(formatPrice)
    const date = new Date().getTime()


    return (
        <div style={{display: 'none'}}>
            <div style={{padding: '0 20px', maxWidth: 900, margin: '0 auto'}} className='print_pro'>
                <table width="100%" style={{fontFamily: 'Arial'}}>
                    <tbody>
                    <tr>
                        <td style={{width: '68%', padding: '20px 0'}}>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div style={{display: 'flex', flexDirection: 'row'}}>

                    <table width="100%" border={2}
                           style={{borderCollapse: 'collapse', width: '100%', fontFamily: 'Arial'}} cellPadding={2}
                           cellSpacing={2}>
                        <tbody>
                        <tr style={{}}>
                            <td colSpan={2} rowSpan={2} style={{minHeight: '13mm', width: '105mm'}}>
                                <table width="100%" border={0} cellPadding={0} cellSpacing={0} style={{height: '13mm'}}>
                                    <tbody>
                                    <tr>
                                        <td valign="top">
                                            <div>ПОВОЛЖСКИЙ ФИЛИАЛ АО <br/> "РАЙФФАЙЗЕНБАНК" г. Нижний Новгород</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td valign="bottom" style={{height: '3mm'}}>
                                            <div style={{fontSize: '10pt'}}>Банк получателя</div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td style={{minHeight: '7mm', height: 'auto', width: '25mm'}}>
                                <div>БИК</div>
                            </td>
                            <td rowSpan={2} style={{verticalAlign: 'top', width: '60mm'}}>
                                <div style={{height: '7mm', lineHeight: '7mm', verticalAlign: 'middle'}}>042202847</div>
                                <div>30101810300000000847</div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: '25mm'}}>
                                <div>Сч. №</div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{minHeight: '6mm', height: 'auto', width: '50mm'}}>
                                <div>ИНН 5906105146</div>
                            </td>
                            <td style={{minHeight: '6mm', height: 'auto', width: '55mm'}}>
                                <div>КПП 590601001</div>
                            </td>
                            <td rowSpan={2}
                                style={{minHeight: '19mm', height: 'auto', verticalAlign: 'top', width: '25mm'}}>
                                <div>Сч. №</div>
                            </td>
                            <td rowSpan={2}
                                style={{minHeight: '19mm', height: 'auto', verticalAlign: 'top', width: '60mm'}}>
                                <div>40702810223000075929</div>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2} style={{minHeight: '13mm', height: 'auto'}}>
                                <table border={0} cellPadding={0} cellSpacing={0}
                                       style={{height: '13mm', width: '105mm'}}>
                                    <tbody>
                                    <tr>
                                        <td valign="top">
                                            <div>ООО "АЙ-ЭМ-АЙ МЕДИА"</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td valign="bottom" style={{height: '3mm'}}>
                                            <div style={{fontSize: '10pt'}}>Получатель</div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <br/>
                <div style={{fontWeight: 'bold', fontSize: '25pt', paddingLeft: '5px', fontFamily: 'Arial'}}>
                    {`${'Счет на оплату №'} t/${props.invoiceNumber}  от `} {moment(date).format('DD.MM.YY')}</div>
                <br/>
                <div style={{backgroundColor: '#000000', width: '100%', fontSize: '1px', height: '2px'}}>&nbsp;</div>
                <table width="100%" style={{fontFamily: 'Arial'}}>
                    <br/>
                    <tbody>
                    <tr>
                        <td style={{width: '30mm', verticalAlign: 'top'}}>
                            <div style={{paddingLeft: '2px'}}>Поставщик <br/>(Исполнитель):</div>
                        </td>
                        <td>
                            <div style={{fontWeight: 'bold', paddingLeft: '2px'}}>
                                ООО "Ай-Эм-Ай МЕДИА" ИНН 5906105146, КПП 590601001, 614070, Пермский край, :Пермь г,
                                Крупской ул, дом No 38, помещение 21 <br/>
                            </div>
                        </td>
                    </tr>
                    <br/>
                    <tr>
                        <td style={{width: '30mm', verticalAlign: 'top'}}>
                            <div style={{paddingLeft: '2px'}}>Покупатель <br/>(Заказчик):</div>
                        </td>
                        <td>
                            <div style={{fontWeight: 'bold', paddingLeft: '2px'}}>
                                {/*<span style={{ fontWeight: 'normal' }}>213245, Российская Федерация, г. ,  пр-кт, д.151 лит. А,<br /></span> */}
                            </div>
                        </td>
                    </tr>
                    </tbody>
                    <br/>
                </table>
                <table border={2} width="100%" cellPadding={2} cellSpacing={2}
                       style={{borderCollapse: 'collapse', width: '100%', fontFamily: 'Arial'}}>
                    <thead>
                    <tr>
                        <th style={{width: '13mm'}}>№</th>
                        <th>Товары (работы, услуги)</th>
                        <th style={{width: '20mm'}}>Кол-во</th>
                        <th style={{width: '17mm'}}>Ед.</th>
                        <th style={{width: '27mm'}}>Цена</th>
                        <th style={{width: '27mm'}}>Сумма</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td style={{width: '13mm'}}>1</td>
                        <td>Подписка TONNA Pro (льготное приобретение): {props.token}</td>
                        <td style={{width: '20mm'}}>1</td>
                        <td style={{width: '17mm'}}>шт</td>
                        <td style={{width: '27mm', textAlign: 'center'}}>91,200.00</td>
                        <td style={{width: '27mm', textAlign: 'center'}}>91,200.00</td>
                    </tr>
                    </tbody>
                </table>
                <table style={{fontFamily: 'Arial'}} border={0} width="100%" cellPadding={1} cellSpacing={1}>
                    <tbody>
                    <tr>
                        <td/>
                        <td style={{width: '27mm', fontWeight: 'bold', textAlign: 'right'}}>Итого:</td>
                        <td style={{width: '27mm', fontWeight: 'bold', textAlign: 'center'}}>91,200.00</td>
                    </tr>
                    <tr>
                        <td/>
                        <td style={{width: '27mm', fontWeight: 'bold', textAlign: 'right'}}>Без налога (НДС)</td>
                        <td style={{width: '27mm', fontWeight: 'bold', textAlign: 'center'}}>-</td>
                    </tr>
                    <tr>
                        <td/>
                        <td style={{width: '37mm', fontWeight: 'bold', textAlign: 'right'}}>Всего к оплате:</td>
                        <td style={{width: '27mm', fontWeight: 'bold', textAlign: 'center'}}>91,200.00</td>
                    </tr>
                    </tbody>
                </table>
                <br/>
                <div style={{fontFamily: 'Arial'}}>
                    Всего наименований 1, на сумму 91,200.00 руб.<br/>
                    <b>{textPrice}</b></div>
                <br/>
                <div style={{fontFamily: 'Arial', fontSize: '10pt'}}>
                    Оплата данного счета означает согласие Заказчика с условиями оказания услуг:<br/>
                    <span>1. Исполнитель обязуется оказать Заказчику услуги, а Заказчик обязуется их принять и оплатить.</span><br/>
                    <span>2. Сведения об оказываемых услугах содержатся в договоре и приложении к договору.</span><br/>
                    <span>3. Оплата услуг осуществляется Заказчиком путем безналичного перевода денежных средств на расчетный счет
                    Исполнителя с обязательным указанием в платежном поручении реквизитов настоящего счета.
                    </span><br/>
                    <span>4. Заказчик обязуется оплатить услуги в соответствии с офертой на сайте tonna.io.</span>
                </div>
                <br/>
                <div style={{backgroundColor: '#000000', width: '100%', fontSize: '1px', height: '2px'}}>&nbsp;</div>
                <br/>

                <br/>
                <div style={{
                    background: 'none',
                    backgroundRepeat: 'no-repeat',
                    padding: '30px 10px',
                    width: '400px',
                    height: '250px'
                }}>
                    <div>Руководитель ______________________</div>
                    <br/> <br/><br/>
                    <div>Бухгалтер ______________________</div>
                    <br/>
                    <br/>
                </div>
                <br/><br/><br/><br/><br/><br/><br/><br/><br/>
            </div>
        </div>
    );
}

export default ProPayment;