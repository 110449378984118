import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { api } from '../../Store/api'

export const fetchNews = createAsyncThunk(
  'news/fetchNews',
  async () => {
    const response = await api.get('public/news')
    return response.data
  }
)

const initialState = () => {
  return (
    {
      news: [],
      loading: false,
      error: {
        failed: false,
        message: null
      }
    }
  )
}

const homeSlice = createSlice({
  name: 'home',
  initialState: initialState(),

  extraReducers: {
    [fetchNews.pending]: (state) => {
      state.loading = true
      state.error.failed = false
    },
    [fetchNews.fulfilled]: (state, action) => {
      state.loading = false
      state.news = action.payload
    },
    [fetchNews.rejected]: (state, action) => {
      state.loading = false
      state.error.failed = true
      state.error.message = action.payload
    }
  }
})

export default homeSlice.reducer
