import { useEffect, useState } from 'react'
import React from 'react'
import { Marker, Popup, useMapEvents } from 'react-leaflet'
import L from 'leaflet'
import { useDispatch } from 'react-redux'
import { changeSearchVal, setLocation } from './selectLocationSlice'
import redMarker from '../../../../assets/icon/redMarker.svg'
function LocationMarker(props) {
    const dispatch = useDispatch()
    const selectedCoords = props.selectedCoords
    const [position, setPosition] = useState(selectedCoords)
    const markerIcon = new L.icon({
        iconUrl: redMarker,
        iconSize: [60, 80]
    })

    const map = useMapEvents({
        drag() {
            setPosition(map.getCenter())
        },
        zoom() {
            setPosition(map.getCenter())
        },
        dragend() {
            dispatch(changeSearchVal(`${position.lat}, ${position.lng}`))
            dispatch(setLocation(({ coords: { lat: position.lat, lng: position.lng } })))
        },
        locationfound(e) {
            setPosition(e.latlng)
            map.flyTo(e.latlng, map.getZoom())
            dispatch(setLocation(({ name: ``, coords: { lat: e.latlng.lat, lng: e.latlng.lng } })))
        },

    })

    useEffect(() => {
        if (selectedCoords) {
            map.panTo(selectedCoords, map.getZoom())
            setPosition(selectedCoords)
        }
    }, [selectedCoords])

    return position === null ? null : (
        <Marker position={position} icon={markerIcon} draggable={false}>
            <Popup>{`${position.lat} ${position.lng}`}</Popup>
        </Marker>
    )
}

export default LocationMarker
