import { useState, useEffect, useRef } from 'react';
import moment from "moment";

const Timer = (date) => {
  const [timer, setTimer] = useState();
  const endDate = moment(date);
  const timerInstance = useRef(null);

  if (!timerInstance.current) {
    timerInstance.current = setInterval(function () {
      let now = new Date();
      setTimer(endDate.diff(now, 'days'));
    }, 1000)
  };

  useEffect(() => {
    return () => clearInterval(timerInstance.current);
  }, []);

  if (timer <= 0) {
    clearInterval(timerInstance.current);
  }

  return timer;
}

export default Timer;
