import React from 'react'
import { NavLink, Link } from 'react-router-dom'
import { ReactComponent as HomeSVG } from '../../../assets/icon/home.svg'
import { ReactComponent as DocumentSVG } from '../../../assets/icon/document.svg'
import { ReactComponent as MapSVG } from '../../../assets/icon/map.svg'
import { ReactComponent as WalletSVG } from '../../../assets/icon/wallet.svg'
import { ReactComponent as MenuSVG } from '../../../assets/icon/menu.svg'
import { ReactComponent as SubscriptionsSVG } from '../../../assets/icon/crown.svg'
import { ReactComponent as NewOfferSVG } from '../../../assets/icon/plus.svg'
import './menu.css'
import { useTranslation } from "react-i18next";

function Menu(props) {
    const { t } = useTranslation()
    return (
        <div
            // style={{ top: props.top }}
            className="menu">
            <Link className="item" activeClassName="menu_item-active" exact to={{ pathname: "https://tonna.io/" }} target="_blank">
                <HomeSVG className="menu-svg" />
            </Link>
            <Link className="item" activeClassName="menu_item-active" to={{ pathname: "https://tonna.io/offers" }} target="_blank">
                <DocumentSVG className="menu-svg" />
            </Link>
            <Link className="item" activeClassName="menu_item-active" to={{ pathname: "https://tonna.io/map" }} target="_blank">
                <MapSVG className="menu-svg" />
            </Link>
            <a href="#" className="menu_item">
                <MenuSVG style={{height: 25}} className="menu-svg" />
                <div className="sub-menu">
                    <Link className="item" activeClassName="menu_item-active" to={{ pathname: "https://tonna.io/wallet" }} target="_blank">
                        <WalletSVG className="menu-svg" />
                        <span>{t('Menu.wallet')}</span>
                    </Link>
                    <Link className="item" activeClassName="menu_item-active" to={{ pathname: "https://tonna.io/newoffer" }} target="_blank">
                        <NewOfferSVG className="offer-svg" />
                        <span>{t('Menu.newRequest')}</span>
                    </Link>
                    <Link className="item" activeClassName="menu_item-active" to={{ pathname: "https://tonna.io/subscriptions" }} target="_blank">
                        <SubscriptionsSVG className="menu-svg" />
                        <span>{t('Menu.subscriptions')}</span>
                    </Link>

                    {/*<span className="sub-menu-item">{t('Applications.waiting')}<div className="circle"></div></span>*/}
                    {/*<span className="sub-menu-item">{t('Applications.applicationManagement')}<div className="circle"></div></span>*/}
                </div>
            </a>
        </div>
    )
}

export default Menu
