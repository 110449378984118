import React, { useEffect, useState } from 'react'
import QRCode from "qrcode.react";
import moment from 'moment'
import { stringToNum } from './masks'
import { useTranslation } from 'react-i18next'
const rubles = require('rubles').rubles

const Invoice = ({ price, invoiceID, customerName, inn, invoiceNum }) => {
    // const price = props.price
    // const invoiceId = props.invoiceId
    const formatPrice = stringToNum(price)
    // const customerName = props.customerName
    // const inn = props.inn
    const textPrice = rubles(formatPrice)
    const { t } = useTranslation()
    const date = new Date().getTime()

    const [qrVal, setQrVal] = useState(``)
    useEffect(() => {
        if (formatPrice) {
            setQrVal(`ST00012|Name=ООО \"АИ-ЭМ-АИ МЕДИА\"|PersonalAcc=40702810401500120800|BankName=ПАО\n \"ТОЧКА БАНКА "ФК ОТКРЫТИЕ"\"|BIC=044525999|CorrespAcc=30101810845250000999|Sum=${String(formatPrice * 100)}|PayeeINN=5906105146|KPP=590601001|Purpose=Пополнение номинального счета:${invoiceID}`)
        }
    }, [formatPrice])
    return (
        <div style={{ display: 'none' }}>
            <div style={{ padding: '0 20px', maxWidth: 900, margin: '0 auto' }} className='print_invoice'>
                <table width="100%" style={{ fontFamily: 'Arial' }}>
                    <tbody><tr>
                        <td style={{ width: '68%', padding: '20px 0' }}>
                        </td>
                    </tr>
                    </tbody></table>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <QRCode
                        id="qr-gen"
                        value={qrVal}
                        size={200}
                        level={"H"}
                        includeMargin={true}
                    />
                    <table width="100%" border={2} style={{ borderCollapse: 'collapse', width: '100%', fontFamily: 'Arial' }} cellPadding={2} cellSpacing={2}>
                        <tbody><tr style={{}}>
                            <td colSpan={2} rowSpan={2} style={{ minHeight: '13mm', width: '105mm' }}>
                                <table width="100%" border={0} cellPadding={0} cellSpacing={0} style={{ height: '13mm' }}>
                                    <tbody><tr>
                                        <td valign="top">
                                            <div>ТОЧКА ПАО БАНКА "ФК ОТКРЫТИЕ" </div>
                                        </td>
                                    </tr>
                                        <tr>
                                            <td valign="bottom" style={{ height: '3mm' }}>
                                                <div style={{ fontSize: '10pt' }}>{t('invoice.beneficiaryBank')}</div>
                                            </td>
                                        </tr>
                                    </tbody></table>
                            </td>
                            <td style={{ minHeight: '7mm', height: 'auto', width: '25mm' }}>
                                <div>{t('invoice.bik')}</div>
                            </td>
                            <td rowSpan={2} style={{ verticalAlign: 'top', width: '60mm' }}>
                                <div style={{ height: '7mm', lineHeight: '7mm', verticalAlign: 'middle' }}>044525999</div>
                                <div>30101810845250000999</div>
                            </td>
                        </tr>
                            <tr>
                                <td style={{ width: '25mm' }}>
                                    <div>{t('invoice.Сorraccount')}</div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ minHeight: '6mm', height: 'auto', width: '50mm' }}>
                                    <div>{t('invoice.inn')} 5906105146 </div>
                                </td>
                                <td style={{ minHeight: '6mm', height: 'auto', width: '55mm' }}>
                                    <div>{t('invoice.kpp')} 590601001</div>
                                </td>
                                <td rowSpan={2} style={{ minHeight: '19mm', height: 'auto', verticalAlign: 'top', width: '25mm' }}>
                                    <div>{t('invoice.account')}</div>
                                </td>
                                <td rowSpan={2} style={{ minHeight: '19mm', height: 'auto', verticalAlign: 'top', width: '60mm' }}>
                                    <div>40702810401500120800</div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2} style={{ minHeight: '13mm', height: 'auto' }}>
                                    <table border={0} cellPadding={0} cellSpacing={0} style={{ height: '13mm', width: '105mm' }}>
                                        <tbody><tr>
                                            <td valign="top">
                                                <div>ООО "АЙ-ЭМ-АЙ МЕДИА"</div>
                                            </td>
                                        </tr>
                                            <tr>
                                                <td valign="bottom" style={{ height: '3mm' }}>
                                                    <div style={{ fontSize: '10pt' }}>{t('invoice.receiver')}</div>
                                                </td>
                                            </tr>
                                        </tbody></table>
                                </td>
                            </tr>
                        </tbody></table></div>
                <br />
                <div style={{ fontWeight: 'bold', fontSize: '25pt', paddingLeft: '5px', fontFamily: 'Arial' }}>
                    {`${'Счет №'} ${invoiceNum} ${'от'}`} {moment(date).format('DD.MM.YY')}</div>
                <br />
                <div style={{ backgroundColor: '#000000', width: '100%', fontSize: '1px', height: '2px' }}>&nbsp;</div>
                <table width="100%" style={{ fontFamily: 'Arial' }}>
                    <br />
                    <tbody><tr>
                        <td style={{ width: '30mm', verticalAlign: 'top' }}>
                            <div style={{ paddingLeft: '2px' }}>{t('invoice.provider')}:  </div>
                        </td>
                        <td>
                            <div style={{ fontWeight: 'bold', paddingLeft: '2px' }}>
                                ООО "АЙ-ЭМ-АЙ МЕДИА" {t('invoice.inn')} 5906105146,<br />
                                <span style={{ fontWeight: 'normal' }}>город Москва<br /></span>
                            </div>
                        </td>
                    </tr>
                        <br />
                        <tr>
                            <td style={{ width: '30mm', verticalAlign: 'top' }}>
                                <div style={{ paddingLeft: '2px' }}>{t('invoice.buyer')}:  </div>
                            </td>
                            <td>
                                <div style={{ fontWeight: 'bold', paddingLeft: '2px' }}>
                                    {customerName} {t('invoice.inn')} {inn}, {t('invoice.kpp')} 45465446456<br />
                                    {/*<span style={{ fontWeight: 'normal' }}>213245, Российская Федерация, г. ,  пр-кт, д.151 лит. А,<br /></span> */}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <br />
                </table>
                <table border={2} width="100%" cellPadding={2} cellSpacing={2} style={{ borderCollapse: 'collapse', width: '100%', fontFamily: 'Arial' }}>
                    <thead>
                        <tr>
                            <th style={{ width: '13mm' }}>№</th>
                            <th>{t('invoice.products')}</th>
                            <th style={{ width: '20mm' }}>{t('invoice.quantity')}</th>
                            <th style={{ width: '17mm' }}>{t('invoice.unit')}.</th>
                            <th style={{ width: '27mm' }}>{t('invoice.price')}</th>
                            <th style={{ width: '27mm' }}>{t('Wallet.sum')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ width: '13mm' }}>1</td>
                            <td>{t('invoice.product')} {invoiceID}</td>
                            <td style={{ width: '20mm' }}>{t('invoice.quantity')}</td>
                            <td style={{ width: '17mm' }}>{t('invoice.service')}.</td>
                            <td style={{ width: '27mm', textAlign: 'center' }}>{price}</td>
                            <td style={{ width: '27mm', textAlign: 'center' }}>{price}</td>
                        </tr>
                    </tbody>
                </table>
                <table style={{ fontFamily: 'Arial' }} border={0} width="100%" cellPadding={1} cellSpacing={1}>
                    <tbody><tr>
                        <td />
                        <td style={{ width: '27mm', fontWeight: 'bold', textAlign: 'right' }}>{t('invoice.summary')}:</td>
                        <td style={{ width: '27mm', fontWeight: 'bold', textAlign: 'center' }}>{price}</td>
                    </tr>
                        <tr>
                            <td />
                            <td style={{ width: '27mm', fontWeight: 'bold', textAlign: 'right' }}>{t('invoice.summary')} {t('invoice.nds')}:</td>
                            <td style={{ width: '27mm', fontWeight: 'bold', textAlign: 'center' }}>{t('invoice.notTaxable')}</td>
                        </tr>
                        <tr>
                            <td />
                            <td style={{ width: '37mm', fontWeight: 'bold', textAlign: 'right' }}>{t('invoice.totalPayable')}:</td>
                            <td style={{ width: '27mm', fontWeight: 'bold', textAlign: 'center' }}>{price}</td>
                        </tr>
                    </tbody></table>
                <br />
                <div style={{ fontFamily: 'Arial' }}>
                    {t('invoice.totalItems')} {price} {t('invoice.totalItemsRubles')}.<br />
                    <b>{textPrice}</b></div>
                <br />
                <div style={{ fontFamily: 'Arial', fontSize: '10pt' }}>
                    {t('invoice.attention')}!<br />
                    {t('invoice.attentionText')}
                </div>
                <br />
                <div style={{ backgroundColor: '#000000', width: '100%', fontSize: '1px', height: '2px' }}>&nbsp;</div>
                <br />

                <br />
                <div style={{ background: 'none', backgroundRepeat: 'no-repeat', padding: '30px 10px', width: '400px', height: '250px' }}>
                    <div>{t('invoice.supervisor')} ______________________ </div>
                    <br />  <br /><br />
                    <div>{t('invoice.chiefAccountant')} ______________________</div>
                    <br />

                    {/* <div style={{ width: '85mm', textAlign: 'center' }}>М.П.</div> */}
                    <br />
                </div>
                <br />  <br /><br /><br />  <br /><br /><br />  <br /><br />
            </div>
        </div>
    )
}
export default Invoice
