import { MapContainer, TileLayer, ZoomControl } from 'react-leaflet'
import LocationMarker from './LocationMarker';
import React from 'react'
import '../../../../App.css'
import 'leaflet/dist/leaflet.css';
import { useSelector } from 'react-redux';

function Map() {
  const selectedCoords = useSelector(state => state.selectLocationSlice.selectedLocation.coords)

  return (
    <div className='map'>
      <MapContainer
        center={{ lat: 55.8289, lng: 37.6733 }}
        zoom={13}
        zoomControl={false}
        scrollWheelZoom={true}>
        <ZoomControl position='bottomleft' />
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <LocationMarker selectedCoords={selectedCoords} />
      </MapContainer>
    </div >
  )
}

export default Map
