import React from 'react';
import './landing.css'
import EarnWithTonna from "./components/EarnWithTonna/EarnWithTonna"
import InterestBenefits from "./components/InterestBenefits/InterestBenefits"
import WorkWithTonna from "./components/WorkWithTonna/WorkWithTonna"
import Benefits from "./components/Benefits/Benefits"
import NeedHelp from "./components/NeedHelp/NeedHelp"
import BecomePartner from "./components/BecomePartner/BecomePartner"

function Landing() {
    return (
        <div className='content-wrapper'>
            <EarnWithTonna />
            <InterestBenefits />
            <WorkWithTonna/>
            <Benefits/>
            <NeedHelp/>
            <BecomePartner/>
        </div>
    );
}

export default Landing;