import React from 'react';
import choseIcon from "../../../../assets/icon/chose.svg";
import markImg from "../../../../assets/icon/mark.svg";
import {useTranslation} from "react-i18next";

const Start = () => {
    const {t} = useTranslation()
    return (
        <div className="subscription_card">
            <span className="subscription_card_title">Start</span>
            <div className='subscription_card_content'>
                <div className='subscription_subtitle_container'>
                    <div className='subscription_card_subtitle'>Free</div>
                    <div className='subscription_card_chose'>
                        <img src={choseIcon} alt=""/>
                        <span style={{marginLeft: '24px', fontSize: '20px'}}>{t('Subscriptions.Start.yourChoice')}</span>
                    </div>
                </div>
                <div className='subscription_card_about'>
                    <div className='subscription_about_block'>
                        <div className='about_items_container'>
                            <img src={markImg} alt=""/>
                            <span style={{marginLeft: '14px'}}><b>5%</b> {t('Subscriptions.Nitro.first')}</span>
                        </div>
                        <div className='about_items_container'>
                            <img src={markImg} alt=""/>
                            <span>1 {t('Subscriptions.Start.second')}</span>
                        </div>
                    </div>
                    <div className='subscription_about_block'>
                        <div className='about_items_container'>
                            <img src={markImg} alt=""/>
                            <span>{t('Subscriptions.Nitro.third')}</span>
                        </div>
                        <div className='about_items_container'>
                            <img src={markImg} alt=""/>
                            <span>{t('Subscriptions.Nitro.fourth')}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Start;