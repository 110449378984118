import React from 'react';
import {getConfig} from "../../configs/config";
import './login.css'
import {useTranslation} from "react-i18next";

function LogIn() {
    const { t } = useTranslation()
    return (
        <div className='content-wrapper'>
            <div className='login_wrapper'>
                <div className='login-content'>
                    <div className='login-content-title'>{t('Login.logIn')} / {t('Login.singUp')}</div>
                    <div className='login-content-text'>
                        {t('Login.text')}
                    </div>
                    <div className='btn_container'>
                        <button onClick={() => {
                            window.location.href = `${getConfig().baseUrl}/oauth2/authorization/keycloak`
                        }} className='btn auth_btn'>
                            EMAIL
                        </button>
                        <button onClick={() => {
                            window.location.href = `${getConfig().baseUrl}/oauth2/authorization/vk`
                        }} className='btn auth_btn' style={{background: '#5C7DB6'}}>
                            VK.COM
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LogIn;