import React from 'react'
import '../../../../i18n'
import './workWithTonna.css'
import 'antd/dist/antd.css'
import { useTranslation } from 'react-i18next'
import Button from "../../../components/Button/Button"
import { HashLink } from 'react-router-hash-link';
import request from "../../../../assets/icon/request.svg"
import phonepic from "../../../../assets/icon/phonepic.svg"
import referallink from "../../../../assets/icon/referallink.svg"
import coin from "../../../../assets/icon/coin.svg"


function WorkWithTonna(props) {
  const { t } = useTranslation()
  return (
    <div className='work-with'>
      <div className='work-title'>
        <h1>{t("LandingPartners.workWithTonna")}</h1>
        <p>{t("LandingPartners.workWithTonnaDescription")}</p>
      </div>
      <div className='work-info'>
        <div className='work-info-left'>
          <div className='work-info-item'>
            <div className='item-left-pic'>
              <img src={request} alt="request" />
            </div>
            <div className='item-left-info'>
                <h2 className='item-left-header'>
                {t("LandingPartners.workWithTonnaInfoApply")} <br />
                {t("LandingPartners.workWithTonnaInfoApply2")}
                </h2>
                <p>{t("LandingPartners.workWithTonnaInfoFillForm")} <br />
                {t("LandingPartners.workWithTonnaInfoFillForm2")}
                </p>
            </div>
          </div>
          <div className='work-info-item'>
            <div className='item-left-pic-link'>
              <img src={referallink} alt="referallink" />
            </div>
            <div className='item-left-info'>
                <h2 className='item-left-header'>
                {t("LandingPartners.workWithTonnaInfoReferal")}<br />
                {t("LandingPartners.workWithTonnaInfoReferal2")}
                </h2>
                <p>{t("LandingPartners.workWithTonnaInfoReferalSoc")} <br />
                {t("LandingPartners.workWithTonnaInfoReferalSoc2")}
                </p>
            </div>
          </div>
          <div className='work-info-item'>
            <div className='item-left-pic-coin'>
              <img src={coin} alt="coin" />
            </div>
            <div className='item-left-info'>
                <h2 className='item-left-header'>
                {t("LandingPartners.workWithTonnaInfoRewarded")}
                </h2>
                <p>
                {t("LandingPartners.workWithTonnaInfoRewardedDescription1")} <br />
                {t("LandingPartners.workWithTonnaInfoRewardedDescription2")} <br />
                {t("LandingPartners.workWithTonnaInfoRewardedDescription3")} <br />
                {t("LandingPartners.workWithTonnaInfoRewardedDescription4")}
                </p>
            </div>
          </div>
          <div className='become-button'>
          <HashLink to="/#form">
            <Button/>
          </HashLink>
          </div>
        </div>
        <div className='work-info-right'>
          <img src={phonepic} alt="phonepic" className='work-info-right-img'/>
        </div>
      </div>
    </div>
  )
}

export default WorkWithTonna
