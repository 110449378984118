import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const GoTermsOfUse = ({ btnName }) => {
    const { t } = useTranslation()
    return (
        <div style={{ color: '#615FFB', margin: '13px 0 21px', lineHeight: '20px' }}>
            {`${t('respond.respondWarningFirst')} ${btnName} ${t('respond.respondWarningSecond')}`} <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>
            <Link
                to={{ pathname: "http://tonna.io/offerta" }} target="_blank" style={{ color: '#615FFB' }}> {t('respond.termsLink')}
            </Link>
            </span>
        </div>
    )
}
export default GoTermsOfUse
