import React, { useEffect, useState } from 'react'
import { OpenStreetMapProvider } from 'leaflet-geosearch'
import { changeSearchVal, setLocation, setPlace } from '../SelectLocationMap/components/selectLocationSlice'
import { Input, Tooltip } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { searchProviderConf } from '../../../Store/api'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useRef } from 'react'

function Search() {
  const [isSelectorHidden, setIsSelectorHidden] = useState(true)
  const [searchResult, setSearchResult] = useState('')
  const [isSearch, setIsSearch] = useState(false)
  const searchVal = useSelector(state => state.selectLocationSlice.searchVal)
  const dispatch = useDispatch()
  const inputRef = useRef(null)
  const { t } = useTranslation()
  const provider = new OpenStreetMapProvider(searchProviderConf)
  const changeHandler = (value) => {
    dispatch(changeSearchVal(value))
  }
  const searchResultItems = searchResult ? searchResult.map((currentItem, index) => {
    const clickHandler = () => {
      dispatch(changeSearchVal(currentItem.label))
      dispatch(setPlace(({ coords: { lat: currentItem.y, lng: currentItem.x } })))
      dispatch(setLocation({
        name: currentItem.label,
        coords: {
          lat: currentItem.y,
          lng: currentItem.x
        }
      }))
      setIsSelectorHidden(!isSelectorHidden)
    }
    return <div key={index.toString()} onClick={clickHandler} className="map-serch-selector_option">{currentItem.label}</div>
  }) : null

  useEffect(() => {
    if (isSearch) {
      setIsSelectorHidden(true)
      async function getResult(e) {
        try {
          setSearchResult([])
          const results = await provider.search({ query: e })
          setSearchResult(results)
          if (results.length > 0 && searchVal.length > 0) {
            setIsSelectorHidden(false)
          }
        } catch (error) {
          console.log(error)
        }
      }

      if (searchVal.length < 1) {
        setIsSelectorHidden(true)
      }
      if (searchVal) {
        getResult(searchVal).then(() => {
          if (searchResult.length === 1) {
            if (searchResult[0].label === searchVal) {
              setIsSelectorHidden(true)
            }
          }
        })
      }
    }
  }, [isSearch])

  const waitChange = (e) => {
    setIsSearch(false)
    const isSearchTim = setTimeout(() => {
      setIsSearch(true)
      clearTimeout(isSearchTim)
    }, 500);
    changeHandler(e.target ? e.target.value : e)
  }
  useEffect(() => {
    waitChange(searchVal)
  }, [searchVal])

  return (
    <>
      <Input ref={inputRef} suffix={<Tooltip title={t('Map.coordsTooltip')}><QuestionCircleOutlined /></Tooltip>} allowClear placeholder={t('Map.address')} value={searchVal} onChange={waitChange} />
      <div hidden={isSelectorHidden} className="map-serch-selector">{searchResultItems}</div>
    </>
  )
}

export default Search
