import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { fetchMyOffers } from '../offersSlice'
import Loader from '../../components/Loader'
import Offer from '../components/Offer'

function MyOffers() {
    const dispatch = useDispatch()

    const requests = useSelector(state => state.offersSlice.requests)
    const isLoaded = useSelector(state => state.offersSlice.offersLoaded)

    const requestItems = isLoaded ? requests.length > 0 ? requests.map((request) => {
        return (
            <Offer key={request.id} data={request} />
        )
    }) : <div style={{ color: '#CBCBCB', textAlign: 'center' }}>Нет заявок</div> : null
    useEffect(() => {

        dispatch(fetchMyOffers())
    }, [dispatch])

    return (
        <section className="PublicOffers">
            <div>
                {isLoaded ? requestItems : <Loader />}
            </div>
        </section>
    )
}

export default MyOffers
