import history from './utils/history'
import './App.css'
import React, { useEffect } from 'react'
import './i18n'
import { Link, Route, Router, Switch } from 'react-router-dom'
import NewOffer from './components/Offers/NewOffer/NewOffer'
import Offers from './components/Offers/Offers'
import SelectLocation from './components/Maps/SelectLocationMap/SelectLocationMap'
import Offer from './components/Offers/Offer/Offer'
import CargoMap from './components/Maps/CargoMap/CargoMap'
import TermsOfUse from './components/components/TermsOfUse/TermsOfUse'
import Subscriptions from './components/components/Subscriptions/Subscriptions'
import Verification from './components/Verification/Verification'
import Wallet from './components/Wallet/Wallet'
import NotFound from './components/NotFound/NotFound'
import Invoice from './components/components/Invoice'
import Offerta from './components/Offerta'
import UserAgreement from './components/UserAgreement'
import { Button, notification } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import Landing from './components/Landing/Landing'
import Header from './components/components/Header/Header'
import Menu from './components/components/Menu/Menu'
import { t } from 'i18next'

import Footer from './components/components/Footer/Footer'
import PersonalData from './components/PersonalData'
import FrameworkContract from './components/FrameworkContract'
import LogIn from "./components/LogIn/LogIn";

function App() {

  const close = () => {
    localStorage.setItem('cookieAccept', true)
  }

  const openNotification = () => {
    const key = `open${Date.now()}`
    const btn = (
      <Button style={{ backgroundColor: '#615ffb', borderColor: '#615ffb' }} type="primary" size="small" onClick={() => {
        notification.close(key)
        close()
      }}>
        {t('cookieOk')}
      </Button>)

    notification.open({
      message: 'COOKIE',
      description: t('cookie'),
      btn,
      key,
      duration: 0,
      onClose: close,
    })
  }

  useEffect(() => {
    if (!localStorage.getItem('cookieAccept')) {
      openNotification()
    }


  }, [openNotification])

  return (
    <div className="App">
        <Router history={history}>
          <Header />
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route exact path="/newoffer" component={NewOffer} />
            <Route path="/newoffer/selectlocation" component={SelectLocation} />
            <Route path="/:entity(offers|trades)" component={Offers} />
            <Route path="/:entity(offer|trade)/:id?" component={Offer} />
            <Route path="/map/:cords?/:id?" component={CargoMap} />
            <Route path="/terms" component={TermsOfUse} />
            <Route path="/offerta" component={Offerta} />
            <Route path="/agreement" component={UserAgreement} />
            <Route path="/frameworkContract" component={FrameworkContract} />
            <Route path="/subscriptions" component={Subscriptions} />
            <Route path="/verification/:category?" component={Verification} />
            <Route path="/wallet" component={Wallet} />
            <Route path='/invoiceTets' component={Invoice} />
            <Route path='/personalData' component={PersonalData} />
            <Route path='/auth' component={LogIn} />
            {/*<Route path='/upload' component={UploadDocs} />*/}
            <Route path="*" component={NotFound} />
          </Switch>
          <Footer />
          <Menu />
          <Link className='new-offer'  to={{ pathname: "http://tonna.io/newoffer" }} target="_blank">
            <Button style={{ width: 62, height: 62 }} type="primary" shape="circle" size='large'>
              <PlusOutlined style={{ width: 30 }} />
            </Button>
          </Link>
        </Router>
    </div>
  )
}

export default App
