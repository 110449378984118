import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { api } from '../../Store/api'
import { message } from 'antd'

export const fetchBalance = createAsyncThunk(
    'wallet/fetchBalance',
    async () => {
        const response = await api.get(`private/wallet`);
        return response.data;
    },
)
export const fetchInvoice = createAsyncThunk(
    'wallet/fetchInvoice',
    async () => {
        const response = await api.post(`private/wallet/invoice`);
        return response.data;
    },
)
export const sendEmulatePayment = createAsyncThunk(
    'wallet/sendEmulatePayment',
    async ({ data }) => {
        const response = await api.post(`private/wallet/emulate_input`, data);
        return response.data;
    },
)
const initialState = () => {
    return {
        wallet: {
            balances: {},
            invoice: null
        },
        balanceLoaded: false,
        invoiceLoaded: false,
        error: {
            failed: false,
            message: null,
        },
    }
}
const walletSlice = createSlice({
    name: 'wallet',
    initialState: initialState(),
    extraReducers: {

        [fetchBalance.pending]: (state) => {
            state.balanceLoaded = false;
            state.error.failed = false;
            state.error.message = null;
        },
        [fetchBalance.fulfilled]: (state, action) => {
            state.wallet.balances = action.payload
            state.balanceLoaded = true;
        },
        [fetchBalance.rejected]: (state, action) => {
            // message.error({
            //     content: 'Что-то пошло не так....',
            //     style: {
            //         marginTop: '17vh'
            //     }
            // })
            state.balanceLoaded = true
            state.error.failed = true;
        },
        [fetchInvoice.pending]: (state) => {
            state.invoiceLoaded = false;
            state.error.failed = false;
            state.error.message = null;
        },
        [fetchInvoice.fulfilled]: (state, action) => {
            state.wallet.invoice = action.payload
            state.invoiceLoaded = true;
        },
        [fetchInvoice.rejected]: (state, action) => {
            // message.error({
            //     content: 'Вам необходимо авторизоваться',
            //     style: {
            //         marginTop: '17vh'
            //     }
            // })
            state.error.failed = true;
            state.invoiceLoaded = true;
        },
        [sendEmulatePayment.pending]: (state) => {
            state.loaded = false;
            state.error.failed = false;
            state.error.message = null;
        },
        [sendEmulatePayment.fulfilled]: (state, action) => {
            message.success({
                content: 'Счет успешно оплачен, деньги поступят в течении 10 минут',
                style: {
                    marginTop: '17vh'
                }
            })

            state.loaded = true;
        },
        [sendEmulatePayment.rejected]: (state, action) => {
            // message.error({
            //     content: 'Что-то пошло не так....',
            //     style: {
            //         marginTop: '17vh'
            //     }
            // })
            state.invoiceLoaded = true;
            state.error.failed = true;
        }
    }
})

export default walletSlice.reducer
