import React, { useEffect, useRef, useState } from 'react'
import { Marker, Popup, useMap, useMapEvents } from 'react-leaflet'
import { changeSearchVal, setPlace } from '../../SelectLocationMap/components/selectLocationSlice'
import { useDispatch } from 'react-redux'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'

function LocationMarker (props) {
  const selectedPlace = props.selectedPlace
  const customMarker = props.customMarker
  const showModal = props.showModal
  const t = props.t
  const [open, setOpen] = useState(true)
  const [close, setClose] = useState(false)
  const dispatch = useDispatch()
  const [position, setPosition] = useState(selectedPlace)
  const myMap = useMap()
  const MyMarker = (props) => {
    const leafletRef = useRef()
    useEffect(() => {
      if (!close && open) {
        leafletRef.current.openPopup()
      }
    }, [])
    return <Marker ref={leafletRef} {...props}  />
  }
  const handleClose = () => {
    myMap.closePopup()
    setClose(true)
  }
  const map = useMapEvents({
    click () {
      map.locate()
    },
    drag () {
      setPosition(map.getCenter())
      setOpen(false)
    },
    // zoom() {
    //   setPosition(map.getCenter())
    // },
    dragend () {
      if (position !== null) {
        dispatch(setPlace(({ coords: { lat: position.lat, lng: position.lng } })))
        dispatch(changeSearchVal(`${position.lat}, ${position.lng}`))
      }
      setOpen(true)
    },
    locationfound (e) {
      map.panTo(e.latlng, map.getZoom())
    },
  })
  useEffect(() => {
    if (selectedPlace) {
      map.panTo(selectedPlace, map.getZoom())
      setPosition(selectedPlace)
    }
  }, [selectedPlace])
  return position === null ? null : (
    <MyMarker position={position} draggable={false} icon={customMarker} autoClose={false} isOpen={true}>
      <Popup position={position} closeButton={false}>
        <div className="marker_title">
              <span style={{display: 'flex'}}>
                {t('Map.markTransport')}
                <Tooltip  title={t('Map.markTooltip')}><QuestionCircleOutlined style={{marginLeft: '2px'}}/></Tooltip>
              </span>
          <span className='close' style={{ padding: 0, cursor: 'pointer' }} onClick={handleClose} >{t('NewApplication.close')}</span>
        </div>

        <button style={{ marginTop: '5px' }} onClick={showModal} className="btn">{t('Map.markTransport')}</button>
      </Popup>
    </MyMarker>
  )
}

export default LocationMarker
