import React from 'react'
import ReactDOM from 'react-dom'
import './assets/fonts/Gilroy-SemiBold.eot'
import './assets/fonts/Gilroy-SemiBold.ttf'
import './assets/fonts/Gilroy-SemiBold.woff'
import './assets/fonts/Gilroy-Thin.ttf'
import './assets/fonts/Gilroy-Thin.woff2'
import './assets/fonts/Gilroy-Thin.woff'
import './assets/fonts/Gilroy-UltraLight.eot'
import './assets/fonts/Gilroy-UltraLight.ttf'
import './assets/fonts/Gilroy-UltraLight.woff'
import history from "./utils/history";
import { Provider } from 'react-redux'
import { persistor, store } from './Store/store'
import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'
import App from './App'
import {getConfig} from "./configs/config";
import { PersistGate } from 'redux-persist/integration/react'

ReactGA.initialize('G-5ZL1N2C16K')

history.listen((location, action) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
})

const tagManagerArgs = {
  gtmId: 'GTM-MZ7SLLP'
}

TagManager.initialize(tagManagerArgs)

const onRedirectCallback = (appState) => {
  history.replace({
    pathname: appState?.returnTo || window.location.pathname,
    search: '',
  });
};

const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  ...(config.audience ? { audience: config.audience } : null),
  redirectUri: window.location.origin,
  onRedirectCallback: onRedirectCallback,
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)

