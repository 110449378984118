import React from 'react'
import '../../../i18n'
import './button.css'
import 'antd/dist/antd.css'
import { useTranslation } from 'react-i18next'


function Button(props) {
  const { t } = useTranslation()
  return (
    <div className="button-container">
      <button className='button'>{t("LandingPartners.buttonInvest")}</button>
    </div>
  )
}

export default Button
