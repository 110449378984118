import React, { useState } from 'react'
import '../../../../i18n'
import './becomePartner.css'
import axios from 'axios';
import 'antd/dist/antd.css'
import {Button, Form, Input, message, Modal, Tooltip} from 'antd'
import { useTranslation } from 'react-i18next'
import Loader from "../../../components/Loader";
import {useDispatch, useSelector} from "react-redux";
import {becomePartner} from "../../landingSlice";
import {logDOM} from "@testing-library/react";


function BecomePartner(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [partnerData, setPartnerData] = useState({Fullname: "", INN: "", Phone: "", Email: ""});
  const loading = useSelector(state => state.landingSlice.loading)
  const fullNameResult = partnerData.Fullname.trim();
  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  const emailRegexResult = emailRegex.test(partnerData.Email);
  const handleChange = e => {
      const { name, value } = e.target;
      setPartnerData(prevState => ({
          ...prevState,
          [name]: value
      }));
  };

  const handleBecomePartner = () => {
      if (emailRegexResult && fullNameResult && partnerData.INN && partnerData.Phone) {
          dispatch(becomePartner({
              bank: partnerData.Fullname,
              contact: partnerData.Phone,
              description: `${partnerData.Email}, ${partnerData.INN}`
          }))
          setPartnerData({Fullname: "", INN: "", Phone: "", Email: ""})
      }
  }


  
  return (
    <div className='become-container' id="form">
      <div className='become-title-container'>
        <h2 className='become-title'>{t("LandingPartners.becomePartner")}</h2>
      </div>
        <Form
            name="basic"
            initialValues={{ remember: true }}
            autoComplete="off"
        >
            <Form.Item
                name="Fullname"
                rules={[{ required: true, message: t('LandingPartners.fullNameWarning') }]}
            >
                <input className='input' onChange={handleChange} name="Fullname" value={partnerData.Fullname} type="text" placeholder={t("LandingPartners.becomePartnerFullname")}/>
            </Form.Item>
            <Form.Item
                name="INN"
                rules={[{ required: true, message: t('LandingPartners.INNWarning') }]}
            >
                <input className='input' onChange={handleChange} name="INN" value={partnerData.INN} type="number" placeholder={t("LandingPartners.becomePartnerTin")}/>
            </Form.Item>
            <Form.Item
                name="Phone"
                rules={[{ required: true, message: t('LandingPartners.phoneNumberWarning') }]}
            >
                <input className='input' onChange={handleChange} name="Phone" value={partnerData.Phone} type="number" placeholder={t("LandingPartners.becomePartnerPhone")}/>
            </Form.Item>
            <Form.Item
                name="password"
                rules={[{ required: true, message: t('LandingPartners.emailWarning') }]}
            >
                <input className='input' onChange={handleChange} name="Email" value={partnerData.email} type="email" placeholder={t("LandingPartners.becomePartnerEmail")}/>
            </Form.Item>
            <Form.Item className='button-container'>
                {loading ? <Loader style={'buttonLoader'}/> :
                    <button disabled={loading} onClick={handleBecomePartner}  className='button' htmlType="submit">
                        { t("LandingPartners.buttonInvest")}
                    </button>}
            </Form.Item>
        </Form>
    </div>
  )
}

export default BecomePartner
