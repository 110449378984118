import React, { useState } from 'react'
import { Input, Modal, Tooltip } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import { useTranslation } from 'react-i18next'
import Bird from '../../assets/icon/walletBird.svg'
import './wallet.css'
import { useEffect } from 'react'
import { fetchBalance, fetchInvoice, sendEmulatePayment } from './walletSlice'
import history from '../../utils/history'
import { fetchBeneficiary } from '../Verification/verificationSlice'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { priceMask } from '../components/masks'
import Invoice from '../components/Invoice'
import { useReactToPrint } from 'react-to-print'
import moment from 'moment'

function Wallet(props) {
  const [isModalVisible, setIsModalVisible] = useState(props.location.state ? true : false)
  const [price, setPrice] = useState(props.location.state ? props.location.state.price : null)
  const [isLoaded, seIsLoaded] = useState(false)
  const isError = useSelector(state => state.walletSlice.error.failed)
  const invoice = useSelector(state => state.walletSlice.wallet.invoice)
  const beneficiary = useSelector(state => state.verificationSlice.beneficiary)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const balance = useSelector(state => state.walletSlice.wallet.balances.balance)
  const [customerName, setCustomerName] = useState(localStorage.getItem('beneficiary'))
  const showModal = () => {
    setIsModalVisible(true)
  }
  const handleOk = () => {

    if (price) {
      handlePrint()
      setIsModalVisible(false)
    }

  }
  const goVerify = () => {
    history.push(`/verification/${'tochka'}`)
  }
  const goOffers = () => {
    history.push('/offers')
  }
  const handleCancel = () => {
    setIsModalVisible(false)
  }
  const priceHandler = (e) => {
    setPrice(priceMask(e.target.value))
  }
  const handlePrint = useReactToPrint({
    content: () => document.querySelector('.print_invoice'),
    documentTitle: `${t('Wallet.payment')} ${moment().format('DD.MM.yyyy')}`
  })
  useEffect(() => {
    try {
      if (beneficiary) {
        let customerData = beneficiary.beneficiary_data
        setCustomerName(customerData.name ? customerData.name : `${customerData.last_name} ${customerData.first_name} ${customerData.middle_name}`)
      }
    } catch (error) {
      setCustomerName('customer')
    }
  }, [beneficiary])

  useEffect(() => {
    // в аргументах был токен
    let promis1 = dispatch(fetchBalance({}))
    let promis2 = dispatch(fetchInvoice({}))
    let promis3 = dispatch(fetchBeneficiary({ verifyType: 'tochka' }))
    Promise.all([promis1, promis2, promis3])
      .then(() => {
        seIsLoaded(true)
      })
  }, [dispatch])
  const demoInput = () => {

    const data = {
      amount: 1000,
      invoice_uuid: invoice.invoice_uuid
    }
    // в аргументах был токен
    dispatch(sendEmulatePayment({ data }))

  }
  return (
    <div className="Wallet main">
      <div className="content-wrapper">
        {!isError && isLoaded ? <h1 className="title">{t('Wallet.myWallet')}</h1> : null}
        {isLoaded && !isError ? <div>
          <div onClick={demoInput} className="Wallet_title"><img src={Bird} alt="" /> {customerName}</div>
          <div className="Wallet_info-wrapper">
            <div className="Wallet_info">
              <div className="column">
                <div className="Wallet_info_title">{t('Wallet.balance')}:</div>
                {balance ? priceMask(balance) : '0'}₽
              </div>
              <div className="column">
                <div className="Wallet_info_title">{t('Wallet.refillDate')}:</div>
                {t('Wallet.noData')}
              </div>
              <Invoice invoiceID={invoice.invoice_uuid} customerName={customerName} inn={beneficiary.inn} price={price} invoiceNum={invoice.invoice_id} />
            </div>
            <button disabled={!isLoaded} onClick={showModal} className="btn">{t('Wallet.refill')}</button>
          </div>
          <Modal
            closeIcon={<div className="close">{t('NewApplication.close')}</div>}
            title={t('Wallet.modalInvoice')}
            okText={t('Wallet.loadAccount')}
            cancelText={t('NewApplication.close')}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}>
            <div className="payment">
              <div className="payment-item"><span className="payment-title">{t('Wallet.payer')}:</span>{customerName}
              </div>
              <div className="payment-item"><span className="payment-title">{t('Wallet.sum')}:</span><Input
                value={price} onChange={priceHandler} suffix={'₽'} placeholder={t('Wallet.fillAmount')}
                style={{ width: 'auto' }} />
                <Tooltip title={t('Wallet.activate')}>
                  <QuestionCircleOutlined style={{ marginLeft: '10px', marginTop: '11px' }} />
                </Tooltip>
              </div>
            </div>
          </Modal>
        </div> : !isError ? <Loader /> :
          <Modal closeIcon={<div className="close"></div>}
            title={t('Verification.verification')}
            okText={t('Wallet.verify')}
            cancelText={t('NewApplication.close')}
            visible={true}
            onOk={goVerify}
            onCancel={goOffers}>
            <div style={{ paddingBottom: '10px' }} className="card_info">{t('Wallet.text')}</div>
          </Modal>
        }
      </div>
    </div>
  )
}

export default Wallet
