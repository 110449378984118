import React from 'react'
import './footer.css'
import { ReactComponent as VkSVG } from '../../../assets/icon/vk.svg'
import { ReactComponent as ArrowSVG } from '../../../assets/icon/arrowRight.svg'
import { ReactComponent as InstaSVG } from '../../../assets/icon/insta.svg'
import { ReactComponent as FacebookSVG } from '../../../assets/icon/facebook.svg'
import { ReactComponent as YoutoobeSVG } from '../../../assets/icon/youtube.svg'
import { ReactComponent as OKSVG } from '../../../assets/icon/ok.svg'
import { ReactComponent as TGSVG } from '../../../assets/icon/tg 1.svg'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
const Footer = () => {

    const scrollAfterClick = () => {
        setTimeout(() => {
            window.scrollTo({
                top: 10,
                behavior: "smooth"
            })
        }, 100)
    }
    const { t } = useTranslation()
    return (
        <footer className="footer">
            <div className="content-wrapper">
                <div style={{ color: '#fff' }} className="footer-wrapper">
                    <div className="navigation">
                        <div className="logo"><a href="/" style={{color:'#fff'}}>TONNA<sup>&reg;</sup></a></div>
                        <h2 className="title">
                            <div className="thin">{t('Lending.preTitle')}</div>
                            {t('Lending.title')}
                        </h2>
                        <nav>
                            <span><Link onClick={scrollAfterClick} style={{ textDecoration: 'none', color: '#fff' }} to={{ pathname: "http://tonna.io/agreement" }} target="_blank">{t('Lending.userAgreement')} <ArrowSVG /></Link></span>
                            <span><Link onClick={scrollAfterClick} style={{ textDecoration: 'none', color: '#fff' }} to={{ pathname: "http://tonna.io/offerta" }} target="_blank">{t('Lending.publicOffer')} <ArrowSVG /></Link></span>
                            <span><Link onClick={scrollAfterClick} style={{ textDecoration: 'none', color: '#fff' }} to={{ pathname: "http://tonna.io/personalData" }} target="_blank">{t('Lending.personalData')} <ArrowSVG /></Link></span>
                            <span><Link onClick={scrollAfterClick} style={{ textDecoration: 'none', color: '#fff' }} to={{ pathname: "http://tonna.io/frameworkContract" }} target="_blank">{t('Lending.frameworkContract')} <ArrowSVG /></Link></span>
                            {/*<span>TONNA <ArrowSVG /></span>*/}
                            {/*<span>{t('Lending.subscriptions')} <ArrowSVG /></span>*/}
                            {/*<span>{t('Lending.gifts')} <ArrowSVG /></span>*/}
                            {/*<span>{t('Lending.conditions')} <ArrowSVG /></span>*/}
                        </nav>
                    </div>
                    <div className="social">
                        <div className="social_item"><a target='_blank' href="https://vk.com/tonnaio"><VkSVG /></a></div>
                        <div className="social_item"><a target='_blank' href="https://ok.ru/group/63030502228145"><OKSVG /></a></div>
                        <div className="social_item"><a target = '_blank' href="https://t.me/tonna_io"><TGSVG /></a></div>
                        <div className="social_item"><a target = '_blank' href="https://www.youtube.com/channel/UC5FHoZKcgHt6R_g__lNZ5eA"><YoutoobeSVG /></a></div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
