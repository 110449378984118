import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import {initReactI18next} from 'react-i18next'
import translationEN from './locales/en/translationEN.json'
import translationRU from './locales/ru/translationRU.json'

const resources = {
  ru: {
    translation: translationRU
  },
  en: {
    translation: translationEN
  }
}
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'ru',
    whitelist: ['ru', 'en'],
    detection: {
      order: ["querystring", "cookie"],
      caches: ["cookie"]
    },
    interpolation: {
      escapeValue: false
    }
  })
export default i18n
