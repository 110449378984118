import React from 'react'
import { ReactComponent as ArrowSvg } from '../../../assets/icon/arrow.svg';
import history from "../../../utils/history";
import './goBack.css'
function GoBack(props) {
  const name = props.prevPageName
  const goBack = () => {
    history.goBack()
  }
  return (
    <div onClick={goBack} className="GoBack">
      <span className='back_to'> <ArrowSvg style={{ height: 19 }} /><div className='back_to_title'>{name}</div></span>
    </div>
  )
}

export default GoBack
