import React from 'react'
import { Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import GoBack from '../../components/GoBack/GoBack'
import Map from './components/Map'
import history from '../../../utils/history'
import { changeUnloadLocation, changeUploadLocation } from '../../Offers/NewOffer/newOfferSlice'
import Search from '../components/Search'

const SelectLocationMap = (props) => {
  const type = props.location.state ? props.location.state.type : null
  const selectedLocation = useSelector(state => state.selectLocationSlice.selectedLocation)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const saveLocation = () => {
    if (selectedLocation) {
      if (type === 'unload') {
        dispatch(changeUnloadLocation(selectedLocation))
      }
      if (type === 'upload') {
        dispatch(changeUploadLocation(selectedLocation))
      }
      history.goBack()
    }
  }

  return (
    <section className="SelectLocation main">
      <div className="content-wrapper">
        <GoBack prevPageName={t('NewApplication.newApplication')} />
        <h1 className="title">{t('Map.map')}</h1>
        <div>
          <span className="serch-container">
            <Search />
            <Button className="select_location_button"
              style={{
                background: '#615ffb',
                height: '40px',
                color: '#ffffff',
                marginLeft: '5px',
                borderRadius: '8px'
              }}
              onClick={saveLocation}>ОК</Button>
          </span>
          <Map />
        </div>
      </div>
    </section>
  )
}

export default SelectLocationMap
