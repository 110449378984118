import React from 'react'
import GoBack from './components/GoBack/GoBack'
import { useTranslation } from 'react-i18next'

function Offerta() {
  const { t } = useTranslation()
  return (
    <section>
      <div className="content-wrapper">
        <GoBack prevPageName={t('Verification.goBack')} />
        <h1 style={{ marginBottom: 20 }} className="title">{t('PublicOffer.publicOffer')}</h1>
        <div>
          <div>
            <div className="offerta_body">
              <div className="offerta_number">№</div>
              <div className="offerta_title">{t('UserAgreement.name')}</div>
              <div className="offerta_text">{t('UserAgreement.content')}</div>
            </div>
            <div className="offerta_body">
              <div className="offerta_number"></div>
              <div className="offerta_title"></div>
              <div className="offerta_text">
                <p>
                  {t('PublicOffer.description')}
                </p>
              </div>
            </div>
            <div className="offerta_body">
              <div className="offerta_number">1</div>
              <div className="offerta_title">{t('UserAgreement.generalRegulations.title')}</div>
              <div className="offerta_text">
                <p>
                  {t('PublicOffer.generalRegulations.first')}
                </p>
                <p>
                  {t('PublicOffer.generalRegulations.second')}
                </p>
                <p>
                  {t('PublicOffer.generalRegulations.third')}
                </p>
                <p>
                  {t('PublicOffer.generalRegulations.fourth')}
                </p>
                <p>
                  {t('PublicOffer.generalRegulations.fifth')}
                </p>
                <p>
                  {t('PublicOffer.generalRegulations.sixth')}
                </p>
                <p>
                  {t('PublicOffer.generalRegulations.seventh')}
                </p>
                <p>
                  {t('PublicOffer.generalRegulations.eighth')}
                </p>
                <p>
                  {t('PublicOffer.generalRegulations.ninth')}
                </p>
                <p>
                  {t('PublicOffer.generalRegulations.tenth')}
                </p>
                <p>
                  {t('PublicOffer.generalRegulations.eleventh')}
                </p>
                <p>
                  {t('PublicOffer.generalRegulations.twelfth')}
                </p>
                <p>
                  {t('PublicOffer.generalRegulations.thirteenth')}
                </p>
                <p>
                  {t('PublicOffer.generalRegulations.fourteenth')}
                </p>
              </div>
            </div>
            <div className="offerta_body">
              <div className="offerta_number">2</div>
              <div className="offerta_title">{t('PublicOffer.WorkingConditions.workingConditions')}</div>
              <div className="offerta_text">
                <p>
                  {t('PublicOffer.WorkingConditions.first')}
                </p>
                <p>
                  {t('PublicOffer.WorkingConditions.second')}
                </p>
                <p>
                  {t('PublicOffer.WorkingConditions.third')}
                </p>
                <p>
                  {t('PublicOffer.WorkingConditions.fourth')}
                </p>
                <p>
                  {t('PublicOffer.WorkingConditions.fifth')}
                </p>
                <p>
                  {t('PublicOffer.WorkingConditions.sixth')}
                </p>
                <p>
                  {t('PublicOffer.WorkingConditions.seventh')}
                </p>
              </div>
            </div>
            <div className="offerta_body">
              <div className="offerta_number">3</div>
              <div className="offerta_title">{t('PublicOffer.Responsibilities.responsibilities')}</div>
              <div className="offerta_text">
                <p>
                  {t('PublicOffer.Responsibilities.first')}
                </p>
                <p>
                  {t('PublicOffer.Responsibilities.second')}
                </p>
                <p>
                  {t('PublicOffer.Responsibilities.third')}
                </p>
                <p>
                  {t('PublicOffer.Responsibilities.fourth')}
                </p>
                <p>
                  {t('PublicOffer.Responsibilities.fifth')}
                </p>
                <p>
                  {t('PublicOffer.Responsibilities.sixth')}
                </p>
                <p>
                  {t('PublicOffer.Responsibilities.seventh')}
                </p>
                <p>
                  {t('PublicOffer.Responsibilities.eighth')}
                </p>
              </div>
            </div>
            <div className="offerta_body">
              <div className="offerta_number">4</div>
              <div className="offerta_title">{t('PublicOffer.TransactionCosts.transactionCosts')}</div>
              <div className="offerta_text">
                <p>
                  {t('PublicOffer.TransactionCosts.first')}
                </p>
                <p>
                  {t('PublicOffer.TransactionCosts.second')}
                </p>
                <p>
                  {t('PublicOffer.TransactionCosts.third')}
                </p>
              </div>
            </div>
            <div className="offerta_body">
              <div className="offerta_number">5</div>
              <div className="offerta_title">{t('PublicOffer.ServiceResponsibility.serviceResponsibility')}</div>
              <div className="offerta_text">
                <p>
                  {t('PublicOffer.ServiceResponsibility.first')}
                </p>
                <p>
                  {t('PublicOffer.ServiceResponsibility.second')}
                </p>
              </div>
            </div>
            <div className="offerta_body">
              <div className="offerta_number">6</div>
              <div className="offerta_title">{t('PublicOffer.OtherConditions.otherConditions')}</div>
              <div className="offerta_text">
                <p>
                  {t('PublicOffer.OtherConditions.first')}
                </p>
                <p>
                  {t('PublicOffer.OtherConditions.second')}
                </p>
                <p>
                  {t('PublicOffer.OtherConditions.third')}
                </p>
                <p>
                  {t('PublicOffer.OtherConditions.fourth')}
                </p>
                <p>
                  {t('PublicOffer.OtherConditions.fifth')}
                </p>
                <p>
                  {t('PublicOffer.OtherConditions.sixth')}
                </p>
                <p>
                  {t('PublicOffer.OtherConditions.seventh')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Offerta
