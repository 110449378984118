import React, { useState, useRef } from 'react'
import { Marker, Popup, useMap } from 'react-leaflet'
import moment from 'moment'
import shareSvg from '../../../../assets/icon/share.svg'
import { Modal, Input, Button } from 'antd'
import {
  TwitterShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  VKShareButton,
  OKShareButton,
} from "react-share";
import { ReactComponent as VKIcon } from '../../../../assets/icon/social/vk.svg'
import { ReactComponent as TelegramIcon } from '../../../../assets/icon/social/telegram.svg'
import { ReactComponent as WhatsappIcon } from '../../../../assets/icon/social/whatsapp.svg'
import { ReactComponent as OKIcon } from '../../../../assets/icon/social/ok.svg'
import { ReactComponent as TwitterIcon } from '../../../../assets/icon/social/twitter.svg'
// import { ReactComponent as FacebookIcon} from '../../../../assets/icon/social/vk.svg'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'

function PopupInfo({ place, t, carMarker, urlParamsId }) {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const map = useMap()
  const selectedPlace = useSelector(state => state.selectLocationSlice.selectedPlace.coords)
  const currentDomain = window.location.origin
  const link = `${currentDomain}/map/${place.upload.x},${place.upload.y}/${place.id}`
  const shareMessage = " Мой транспорт в TONNA";
  const [isCopy, setIsCopy] = useState(false)
  const [refReady, setRefReady] = useState(false);
  let currentPopUp = useRef(null)
  const showModal = () => {
    setIsModalVisible(true)
  }
  const handleCancel = () => {
    setIsModalVisible(false)
  }
  const handleOk = () => {
    setIsModalVisible(false)
  }
  const handleClose = () => {
    map.closePopup()
  }
  const copyToClipboard = () => {
    navigator.clipboard.writeText(link)
    setIsCopy(true)
  }

  useEffect(() => {
    if (place.id == urlParamsId && refReady) {
      map.openPopup(currentPopUp)
    }
  }, [map, place.id, refReady, urlParamsId])
  return (
    <>
      <Marker position={[place.upload.x, place.upload.y]} key={place.id} icon={carMarker} draggable={false}>
        <Popup ref={(r) => {
          currentPopUp = r
          setRefReady(true)
        }} className={'someRef' + place.id} open={true} closeButton={false}>
          <div>
            <div className="marker_title">
              <span>
                {place.transport}
              </span>
              <span className='close' style={{ padding: 0, cursor: 'pointer' }} onClick={handleClose} >{t('NewApplication.close')}</span>
            </div>
            <div
              className="offer_body_item">{t('NewApplication.country')}: <span>{place.upload.address.country}</span>
            </div>
            <div
              className="offer_body_item">{t('Map.place')}: <span>{place.upload.address.city ? place.upload.address.city : place.upload.address.county}</span>
            </div>
            <div className="offer_body_item">{t('Map.time')}: <span>{moment(place.date).format('DD.MM.YY')}</span></div>
            <div className="offer_body_item">{t('Map.otherInformation')}: <span>{place.terms}</span></div>
            <div className="offer_body_item">{t('Map.share')}:
              <div onClick={() => { showModal() }} style={{ display: 'flex', flexWrap: 'wrap', marginLeft: '14px' }}>
                <img src={shareSvg}  alt='Поделиться'/>
              </div>

              <span>
              </span></div>
            {/* <div className="offer_body_item">Ваша ссылка<span>{`${currentDomain}/map/${i.upload.x},${i.upload.y}`}</span></div> */}
            <a style={{ color: '#ffffff' }} href={`tel:${place.contact}`}>
              <button className="btn red" style={{ marginTop: '25px' }}>{t('Map.call')}</button>
            </a>
          </div>
        </Popup>
        <Modal closeIcon={<div className='close'>{t('NewApplication.close')}</div>} title={t('NewApplication.share')} okText={'OK'} cancelText={t('NewApplication.rejection')}
          visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
          <span style={{ display: 'flex' }}> <Input disabled={true} value={link} /> <Button disabled={isCopy} onClick={copyToClipboard} style={{ width: '60px', marginLeft: '5px' }} >{isCopy ? t('NewApplication.copied') : t('NewApplication.copy')}</Button></span>
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '18px 0' }}>
            <VKShareButton
              url={link}
              title={shareMessage}>
              <VKIcon
                style={{ height: '40px', width: '40px' }} />
            </VKShareButton>
            <WhatsappShareButton
              url={link}
              title={shareMessage}>
              <WhatsappIcon
                style={{ height: '40px', width: '40px' }} />
            </WhatsappShareButton>
            <TwitterShareButton
              url={link}
              title={shareMessage}>
              <TwitterIcon
                style={{ height: '40px', width: '40px' }} />
            </TwitterShareButton>
            <TelegramShareButton
              url={link}
              title={shareMessage}>
              <TelegramIcon
                style={{ height: '40px', width: '40px' }} />
            </TelegramShareButton>
            <OKShareButton
              url={link}
              title={shareMessage}>
              <OKIcon
                style={{ height: '40px', width: '40px' }} />
            </OKShareButton>
          </div>
        </Modal>
      </Marker>

    </>
  )
}
export default PopupInfo
