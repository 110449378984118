import React from 'react'
import '../../../../i18n'
import './interestBenefits.css'
import 'antd/dist/antd.css'
import { useTranslation } from 'react-i18next'


function InterestBenefits(props) {
  const { t } = useTranslation()
  return (
    <div className="container-interest">
      <div className='item-interest'>
        <h2 className='percent-title'>{t("LandingPartners.interestReferalPercent")}</h2>
          <p className='percent-description'>
          {t("LandingPartners.interestReferalDescription1")} <br /> 
          {t("LandingPartners.interestReferalDescription2")} 
          </p>
      </div>
      <div className='item-interest'>
        <h2 className='percent-title'>{t("LandingPartners.interestReferalIntegrationPercent")}</h2>
          <p className='percent-description'>
          {t("LandingPartners.interestReferalIntegrationDescription1")} <br />
          {t("LandingPartners.interestReferalIntegrationDescription2")}
          </p>
      </div>
      <div className='item-interest'>
        <h2 className='percent-title'>{t("LandingPartners.interestReferalSupport")}</h2>
          <p className='percent-description'>
          {t("LandingPartners.interestReferalSupportDescription1")} <br />
          {t("LandingPartners.interestReferalSupportDescription2")}
          </p>
      </div>
      <div className='item-interest'>
        <h2 className='percent-title'>{t("LandingPartners.interestReferalIncreasePercent")}</h2>
          <p className='percent-description'>
          {t("LandingPartners.interestReferalIncreaseDescription1")} <br /> 
          {t("LandingPartners.interestReferalIncreaseDescription2")}
          </p>
      </div>
    </div>
  )
}

export default InterestBenefits
