import React, { useEffect, useState } from 'react'
import { Modal, Upload, Button, message } from 'antd'
import { UploadOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux'
import { fetchFiles } from '../../offersSlice';
import { getConfig } from '../../../../configs/config';

export default function UploadDocs({ id }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch()
  const showModal = () => {
    setIsModalVisible(true);
  };


  const handleOk = () => {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const props = {
    action: getConfig().baseUrl + `/api/v1/private/files/${id}`,
    name: 'file',
    listType: 'picture',
    // data: { _csrf: document.cookie.split(' ').filter((item) => item.split('XSRF-TOKEN=').length > 1)[0].split('XSRF-TOKEN=')[1].split(';')[0] },

  }
  useEffect(() => {
    if (!isModalVisible) {
      dispatch(fetchFiles({ id }))
    }

  }, [isModalVisible])
  return (
    <div style={{
      display: 'block', maxWidth: '400px', marginTop: '20px'
    }}>
      <button className='btn' onClick={showModal}>Приложить документы</button>
      <Modal visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <Upload {...props}
          onChange={(response) => {
            if (response.file.status !== 'uploading') {
              console.log(response.file, response.fileList)
            }
            if (response.file.status === 'done') {
              message.success(`${response.file.name}
							file uploaded successfully`)
            } else if (response.file.status === 'error') {
              message.error(`${response.file.name}
							file upload failed.`)
            }
          }}
        >
          <Button className='dowanloadBtn' icon={<UploadOutlined />}>Загрузить</Button>
        </Upload>
      </Modal>
    </div>
  )
}
