import React from 'react'
import '../../../../i18n'
import './earn.css'
import 'antd/dist/antd.css'
import { useTranslation } from 'react-i18next'
import Button from "../../../components/Button/Button"
import { HashLink } from 'react-router-hash-link';
import partnersDiagram from "../../../../assets/icon/partnersdiagram.svg"


function EarnWithTonna(props) {
  const { t } = useTranslation()
  return (
    <div className="container-earn">
      <h1 className='container-earn-title'>{t("LandingPartners.earnWith")}</h1>
      <p className='container-earn-description'>{t("LandingPartners.earnDescription1")}<br />
        {t("LandingPartners.earnDescription2")} <br />
        {t("LandingPartners.earnDescription3")}
      </p>
      <HashLink to="/#form">
        <Button/>
      </HashLink>
      <div className='container-earn-diagram'>
        <img src={partnersDiagram} alt="" className='container-earn-diagram-img'/>
      </div>
    </div>
  )
}

export default EarnWithTonna
