import axios from 'axios';
import { getConfig } from '../configs/config';
let defaultOptions = {
  baseURL: getConfig().baseUrl + '/api/v1/',
  headers: {
    'Content-Type': 'application/json',
  },
};


export const searchProviderConf = {
  searchUrl: getConfig().baseUrl + '/nominatim/search',
  reverseUrl: getConfig().baseUrl + '/nominatim/reverse'
}


export const api = axios.create(defaultOptions);

api.interceptors.request.use(function (config) {
  const username = 'user';
  const password = 'password';
  const credentials = btoa(username + ':' + password);
  const basicAuth = 'Basic ' + credentials;
  const basicAuthEnabled = getConfig().basicAuthEnabled

  if (basicAuthEnabled) {
    config.headers.Authorization = basicAuth;
  }
  return config;
}, function (error) {
  // Do something with request error
  console.log('error', error);
  return Promise.reject(error);
});

api.interceptors.response.use(
  function (response) {
    // console.log(response)
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  }, function (error) {
    // console.log(error.response)
    if(error.response.status === 401 && error.response.config.url.startsWith("private") )
    window.location.href = '/auth'
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    // Cookie: i18next=ru; _gid=GA1.2.818083032.1648299390; JSESSIONID=88E29F315F68A98D75CC443891CDD0FD; XSRF-TOKEN=f420e0e0-6167-449c-a023-46aa5c5456f3; _ga_5ZL1N2C16K=GS1.1.1648325039.29.1.1648326402.0; _ga=GA1.2.849344583.1642867140
    return Promise.reject(error);
  }


);

