import React from 'react'
import '../../../../i18n'
import './benefits.css'
import 'antd/dist/antd.css'
import { useTranslation } from 'react-i18next'
import follow from "../../../../assets/icon/follow.svg";
import bank from "../../../../assets/icon/bank.svg"
import smile from "../../../../assets/icon/smile.svg"


function Benefits(props) {
  const { t } = useTranslation()
  return (
    <div className='benefits-container'>
      <div className='title-benefits-container'><h2 className='title-benefits'>{t("LandingPartners.benefitTitle")}</h2></div>
      <div className='benefits-item-container'>
        <div className='benefits-item'>
          <div className='benefits-icon'>
            <img src={follow} alt="follow" />
          </div>
            <h3 className='item-title'>
            {t("LandingPartners.benefitCommunity")} <br /> 
            {t("LandingPartners.benefitCommunity2")}
            </h3>
            <p className='item-description'>
            {t("LandingPartners.benefitCommunityDescription1")} <br /> 
            {t("LandingPartners.benefitCommunityDescription2")} <br /> 
            {t("LandingPartners.benefitCommunityDescription3")} <br /> 
            {t("LandingPartners.benefitCommunityDescription4")}
            </p>
        </div>
        <div className='benefits-item'>
          <div className='benefits-icon'>
            <img src={bank} alt="bank" />
          </div>
            <h3 className='item-title'>
            {t("LandingPartners.benefitCountable")}
            </h3>
            <p className='item-description'>
            {t("LandingPartners.benefitCountableDescription1")} <br /> 
            {t("LandingPartners.benefitCountableDescription2")} <br />
            {t("LandingPartners.benefitCountableDescription3")}<br /> 
            {t("LandingPartners.benefitCountableDescription4")}
            </p>
        </div>
        <div className='benefits-item'>
          <div className='benefits-icon'>
            <img src={smile} alt="smile" />
          </div>
            <h3 className='item-title'>
            {t("LandingPartners.benefitSupport")}
            </h3>
            <p className='item-description'>
            {t("LandingPartners.benefitSupportDescription1")} <br /> 
            {t("LandingPartners.benefitSupportDescription2")} <br />
            {t("LandingPartners.benefitSupportDescription3")} <br />
            {t("LandingPartners.benefitSupportDescription4")} <br />
            {t("LandingPartners.benefitSupportDescription5")} 
            </p>
        </div>
      </div>
    </div>
  )
}

export default Benefits
