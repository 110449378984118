import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchBalance} from "../Wallet/walletSlice";
import {message} from "antd";
import {api} from "../../Store/api";


export const becomePartner = createAsyncThunk(
    'partners/becomePartner',
    async ({ bank, contact, description  }) => {
        const data = {
            bank: bank,
            description: description,
            contact: contact
        }
        const response = await api.post(`public/request_service`, data);
        return response.data;
    },
)

const initialState = () => {
    return {
        loading: false,
        error: {
            failed: false,
            message: null,
        },
    }
}

const landingSlice = createSlice({
    name: 'landing',
    initialState: initialState(),
    extraReducers: {
        [becomePartner.pending]: (state) => {
            state.loading = true;
            state.error.failed = false;
            state.error.message = null;
        },
        [becomePartner.fulfilled]: (state) => {
            state.loading = false;
            message.success({
                content: 'Заявка успешно отправлена',
                style: {
                    marginTop: '17vh'
                }
            })
        },
        [becomePartner.rejected]: (state) => {
            state.loading = false
            state.error.failed = true
            message.error({
                content: 'Не получилось зарегистрироваться? Мы в чате поддержки и готовы помочь',
                style: {
                    marginTop: '17vh'
                }
            })
        }
    }
})

export default landingSlice.reducer