import React, { useEffect } from 'react';
import markImg from "../../../../assets/icon/mark.svg";
import {useTranslation} from "react-i18next";
import ProPayment from "./ProPayment";
import {useReactToPrint} from "react-to-print";
import moment from "moment";
import { useSelector, useDispatch } from 'react-redux'
import Timer from '../../Timer';
import GetRightEnding from '../../GetRightEnding';
import { fetchInvoice } from '../../../Wallet/walletSlice';
import { increaseInvoiceNumber } from '../subscriptionsSlice';
import { GetInvoiceNumber } from '../../GetInvoiceNumber';

const Pro = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const invoice = useSelector(state => state.walletSlice.wallet.invoice)
    const invoiceNumber = useSelector(state => state.subscriptionsSlice.invoiceNumber)

    useEffect(() => {
        dispatch(fetchInvoice({}))
    }, [dispatch])
    
    const handlePrint = useReactToPrint({
        content: () => document.querySelector('.print_pro'),
        documentTitle: `Счет на оплату подписки TONNA Pro ${moment().format('DD.MM.yyyy')}`
    })

    return (
        <div className="subscription_card" style={{background: '#45A484'}}>
            <ProPayment token={invoice?.invoice_uuid} invoiceNumber={GetInvoiceNumber(invoiceNumber)}/>
            <div className='subscription_title_container'>
                <span className="subscription_card_title">Pro</span>
                <span className='subscription_card_time' style={{fontWeight: '400'}}>{t('Subscriptions.Nitro.launching')} {Timer([2022, 8, 23])} {t(`Subscriptions.Nitro.${GetRightEnding(Timer([2022, 8, 23]))}`)}</span>
            </div>
            <div className='subscription_card_content'>
                <div className='subscription_subtitle_container'>
                    <div className='subscription_card_price'>₽ <strike style={{marginRight: '17px', fontSize: '20px'}}>228
                        000</strike> ₽<b style={{fontSize: '20px'}}> 91 200</b>/{t('Subscriptions.Nitro.year')}
                    </div>
                    <button onClick={() => {handlePrint(); dispatch(increaseInvoiceNumber())}} style={{color: '#45A484', marginTop: '21px'}}
                            className="chose_btn">{t('Subscriptions.btn')}
                    </button>
                </div>
                <div className='subscription_card_about'>
                    <div className='subscription_about_block'>
                        <div className='about_items_container'>
                            <img src={markImg} alt=""/>
                            <span
                                style={{marginLeft: '14px'}}><strong>3% </strong>{t('Subscriptions.Nitro.first')}</span>
                        </div>
                        <div className='about_items_container'>
                            <img src={markImg} alt=""/>
                            <span
                                style={{marginLeft: '14px'}}><strong>30 </strong>{t('Subscriptions.Nitro.second')}</span>
                        </div>
                    </div>
                    <div className='subscription_about_block'>
                        <div className='about_items_container'>
                            <img src={markImg} alt=""/>
                            <span style={{marginLeft: '14px'}}>{t('Subscriptions.Nitro.third')}</span>
                        </div>
                        <div className='about_items_container'>
                            <img src={markImg} alt=""/>
                            <span style={{marginLeft: '14px'}}>{t('Subscriptions.Nitro.fourth')}</span>
                        </div>
                    </div>
                    <div className='subscription_about_block'>
                        <div className='about_items_container'>
                            <img src={markImg} alt=""/>
                            <span
                                style={{marginLeft: '14px'}}>{t('Subscriptions.Nitro.fifth')}
                                <strong>{t('Subscriptions.Pro.fifthStrong')}</strong></span>
                        </div>
                        <div className='about_items_container'>
                            <img src={markImg} alt=""/>
                            <span style={{marginLeft: '14px'}}>{t('Subscriptions.Nitro.sixth')}</span>
                        </div>
                    </div>
                    <div className='subscription_about_block'>
                        <div className='about_items_container'>
                            <img src={markImg} alt=""/>
                            <span
                                style={{marginLeft: '14px'}}>{t('Subscriptions.Pro.first')}</span>
                        </div>
                        <div className='about_items_container'>
                            <img src={markImg} alt=""/>
                            <span style={{marginLeft: '14px'}}>{t('Subscriptions.Pro.second')}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Pro;