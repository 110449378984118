import React from 'react'
import { Select, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import PublicOffers from './PublicOffers/PublicOffers'
import { ReactComponent as ArrowSVG } from '../../../src/assets/icon/arrow-price-filter.svg'
import history from "../../utils/history";
import { Route, Router } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import './offers.css'
import MyOffers from './MyOffers/MyOffers'
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import { filterByDate, filterByPrice } from './offersSlice';
import MyTrades from './MyTrades/MyTrades'

const { Option } = Select
function Offers() {
  const { t } = useTranslation()

  const [angal, setAngal] = useState(180)
  const [angalDate, setAngalDate] = useState(180)
  const [filterPriceFromLow, setFilterPriceFromLow] = useState(false)
  const [filterDateFromLow, setFilterDateFromLow] = useState(false)
  const dispatch = useDispatch()
  const filterClickHandlerDate = () => {
    setFilterDateFromLow(!filterDateFromLow)
    setAngalDate(angalDate + 180)
    dispatch(filterByDate(filterDateFromLow))
  }

  const filterClickHandler = () => {
    setFilterPriceFromLow(!filterPriceFromLow)
    setAngal(angal + 180)
    dispatch(filterByPrice(filterPriceFromLow))
  }

  return (
    <section className='Offers main'>
      <div className='content-wrapper'>
        <div style={{ display: 'flex' }}>
          <h1 className='title'>{t("Applications.applicationManagement")}</h1>
          <Tooltip title={t('Applications.tooltip')}>
            <QuestionCircleOutlined style={{ marginLeft: '10px', marginTop: '11px' }} />
          </Tooltip>
        </div>
        <div className='offers_menu'>
          <Link activeClassName='menu_item-active' exact to={{ pathname: "http://tonna.io/offers/" }} target="_blank" className='menu_item'>{t('Applications.allRequests')}</Link>
          <Link activeClassName='menu_item-active' to={{ pathname: "http://tonna.io//offers/my" }} target="_blank" className='menu_item'>{t("Applications.applications")}</Link>
          <Link activeClassName='menu_item-active' to={{ pathname: "http://tonna.io/trades/" }} target="_blank" className='menu_item'>{t('Applications.myDeals')}</Link>
        </div>
      </div>
      <hr />
      <div className='content-wrapper'>
        <div className="PublicOffers-filter">
          <Select
            mode='multiple'
            showSearch
            style={{ width: 200, visibility: 'hidden' }}
            placeholder={t('Applications.search')}
            maxTagCount="responsive"
          >
            <Option value="1">Краснодар</Option>
            <Option value="2">Москва</Option>
            <Option value="3">Чечня</Option>
          </Select>
          <div className='offers-filter'>
            <button onClick={filterClickHandlerDate} className="no-styles btn-text">{t('Applications.date')} <ArrowSVG
              style={{ transform: `rotate(${angalDate}deg)`, marginBottom: '3px', marginRight: '15px' }} width={10} /></button>

            <button onClick={filterClickHandler} className="no-styles btn-text">{t('Applications.price')} <ArrowSVG
              style={{ transform: `rotate(${angal}deg)`, marginBottom: '3px' }} width={10} /></button>

          </div>
        </div>
        <div className="Application_content">
          <Router history={history}>
            <Route exact path='/offers/' component={PublicOffers} />
            <Route path='/offers/my' component={MyOffers} />
            <Route path='/trades/' component={MyTrades} />
          </Router>
        </div>
      </div>
    </section>
  )
}

export default Offers
