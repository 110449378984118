import { Spin } from 'antd'
import React from 'react'
import { LoadingOutlined } from '@ant-design/icons';

const Loader = ({style}) => {
    const antIcon = <LoadingOutlined style={{ fontSize: 40, color: '#615ffb' }} spin />
    return (
        <div style={style ? {minHeight: '15vh'} : ''} className='Loader'><Spin indicator={antIcon} size="large" /></div>
    )
}
export default Loader