import React from 'react'
import './subscriptions.css'
import Start from "./Start/Start";
import Nitro from "./Nitro/Nitro";
import Pro from "./Pro/Pro";
import {useTranslation} from "react-i18next";

function Subscriptions() {
    const {t} = useTranslation()

    return (
        <section className="Subscriptions main">
            <div className="content-wrapper">
                <h1 className="title">{t('Subscriptions.PageTitle')}</h1>
                <div style={{maxWidth: '662px', marginBottom: '26px'}}>
                    <b>{t('Subscriptions.pageAboutStrongText')}</b><br/>
                    {t('Subscriptions.pageAbout')}
                </div>
                <div className='subscription_card_container'>
                    <Start/>
                    <Nitro/>
                    <Pro/>
                </div>
            </div>
        </section>
    )
}

export default Subscriptions
