import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { api } from '../../Store/api'
import { message } from 'antd'

export const sendBeneficiary = createAsyncThunk(
    'verification/sendBeneficiary',
    async (data) => {
        const response = await api.post(`private/beneficiary/${data.type}?category=${data.verifyType}`, data.form);
        return response.data;
    },
);
export const fetchBeneficiary = createAsyncThunk(
    'verification/fetchBeneficiary',
    async ({ verifyType }) => {
        const response = await api.get(`private/beneficiary?category=${verifyType}`);
        return response.data;
    },
)
const initialState = () => {
    return {
        beneficiary: {
        },
        sendBeneficiary: {
            loaded: true,
            error: {
                failed: false,
                message: null,
            },
        },
        fetchBeneficiary: {
            loaded: false,
            error: {
                failed: false,
                message: null,
            },
        }
    }
}

const verificationSlice = createSlice({
    name: 'verification',
    initialState: initialState(),
    extraReducers: {
        [sendBeneficiary.pending]: (state) => {
            state.sendBeneficiary.loaded = false
            state.sendBeneficiary.error.failed = false;
            state.sendBeneficiary.error.message = null;

        },
        [sendBeneficiary.fulfilled]: (state, action) => {
            message.success({
                content: 'Успешно',
                style: {
                    marginTop: '17vh'
                }
            })
            state.sendBeneficiary.loaded = true
        },
        [sendBeneficiary.rejected]: (state, action) => {
            state.sendBeneficiary.error.failed = true;
            state.sendBeneficiary.error.message = action.error
            state.sendBeneficiary.loaded = true
            message.error({
                content: 'Не получилось зарегистрироваться? Мы в чате поддержки и готовы помочь',
                style: {
                    marginTop: '17vh'
                }
            })


        },

        [fetchBeneficiary.pending]: (state) => {
            state.fetchBeneficiary.error.failed = false;
            state.fetchBeneficiary.error.message = null;
            state.fetchBeneficiary.loaded = false
        },
        [fetchBeneficiary.fulfilled]: (state, action) => {
            state.beneficiary = action.payload
            state.fetchBeneficiary.loaded = true
            localStorage.setItem('beneficiary', JSON.stringify(state.beneficiary))

        },
        [fetchBeneficiary.rejected]: (state, action) => {
            // message.error({
            //     content: 'Что-то пошло не так....',
            //     style: {
            //         marginTop: '17vh'
            //     }
            // })
            localStorage.removeItem('beneficiary')
            state.beneficiary = null
            state.fetchBeneficiary.loaded = true
            state.fetchBeneficiary.error.failed = true;

        }
    }
})

export default verificationSlice.reducer
