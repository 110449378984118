export function priceMask(num) {
    if (num) {
        return num.toString().replace(/[^+\d]/g, '').replace(/\s/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

}
export function creditCardMask(card) {
    const value = card.replace(/\D/g, '').substring(0, 18)
    return value !== '' ? value.match(/.{1,4}/g).join(' ') : ''
}
export function stringToNum(str) {
    if (str) {
        return str?.toString().replace(/[a-zа-яё]/gi, '').replace(/\s/g, '') / 1
    }
}
export function phoneNumMask(value) {



}

