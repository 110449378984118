import React from 'react'
import GoBack from './components/GoBack/GoBack'
import { useTranslation } from 'react-i18next'

function Offerta() {
  const { t } = useTranslation()
  return (
    <section>
      <div className="content-wrapper">
        <GoBack prevPageName={t('Verification.goBack')} />
        <h1 style={{ marginBottom: 20 }} className="title">{t('UserAgreement.userAgreement')}</h1>
        <div>
          <div>
            <div className="offerta_body">
              <div className="offerta_number">№</div>
              <div className="offerta_title">{t('UserAgreement.name')}</div>
              <div className="offerta_text">{t('UserAgreement.content')}</div>
            </div>
            <div className="offerta_body">
              <div className="offerta_number"></div>
              <div className="offerta_title"></div>
              <div className="offerta_text">
                <p>
                  {t('UserAgreement.description')}
                </p>
              </div>
            </div>
            <div className="offerta_body">
              <div className="offerta_number">1</div>
              <div className="offerta_title">{t('UserAgreement.generalRegulations.title')}</div>
              <div className="offerta_text">
                <p>
                  {t('UserAgreement.generalRegulations.first')}
                </p>
                <p>
                  {t('UserAgreement.generalRegulations.second')}
                </p>
                <p>
                  {t('UserAgreement.generalRegulations.third')}
                </p>
              </div>
            </div>
            <div className="offerta_body">
              <div className="offerta_number">2</div>
              <div className="offerta_title">{t('UserAgreement.UserResponsibilities.userResponsibilities')}
              </div>
              <div className="offerta_text">
                <p>
                  {t('UserAgreement.UserResponsibilities.first')}
                </p>
                <p>
                  {t('UserAgreement.UserResponsibilities.second')}
                </p>
                <p>
                  {t('UserAgreement.UserResponsibilities.third')}
                </p>
                <p>
                  {t('UserAgreement.UserResponsibilities.fourth')}
                </p>
                <p>
                  {t('UserAgreement.UserResponsibilities.fifth')}
                </p>
              </div>
            </div>
            <div className="offerta_body">
              <div className="offerta_number">3</div>
              <div className="offerta_title">
                {t('UserAgreement.ProcessingOfPersonalData.processingOfPersonalData')}
              </div>
              <div className="offerta_text">
                <p>
                  {t('UserAgreement.ProcessingOfPersonalData.first')}
                </p>
                <p>
                  {t('UserAgreement.ProcessingOfPersonalData.second')}
                </p>
              </div>
            </div>
            <div className="offerta_body">
              <div className="offerta_number">4</div>
              <div className="offerta_title">{t('UserAgreement.OtherConditions.otherConditions')}</div>
              <div className="offerta_text">
                <p>
                  {t('UserAgreement.OtherConditions.first')}
                </p>
                <p>
                  {t('UserAgreement.OtherConditions.second')}
                </p>
                <p>
                  {t('UserAgreement.OtherConditions.third')}
                </p>
                <p>
                  {t('UserAgreement.OtherConditions.fourth')}
                </p>
                <p>
                  {t('UserAgreement.OtherConditions.fifth')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Offerta
