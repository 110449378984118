import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { api } from '../../../Store/api'
import { message } from 'antd'

export const newOfferSend = createAsyncThunk(
  'newOffer/form',
  async (data) => {
    try {
      const response = await api.post('private/offer', data.form);
      return response.data;
    }
    catch (e) {
      throw (e)
    }
  },
);
const initialState = () => {
  return {
    loading: false,
    adding: false,
    form: {},
    error: {
      failed: false,
      message: null,
    },
  }
}
const newOfferSlice = createSlice({
  name: 'newOffer',
  initialState: initialState(),
  reducers: {
    changeUnloadLocation(state, action) {
      state.form.unload = action.payload
    },
    changeUploadLocation(state, action) {
      state.form.upload = action.payload
    },
    changeDate(state, action) {
      state.form.date = action.payload
    },
    changeTerms(state, action) {
      state.form.terms = action.payload
    },
    changePrice(state, action) {
      state.form.price = action.payload
    },
    changeNumber(state, action) {
      state.form.phoneNumber = action.payload
    },
    changeTransportation(state, action) {
      state.form.transportation = action.payload
    }

  },
  extraReducers: {
    [newOfferSend.pending]: (state) => {
      state.adding = true;
      state.error.failed = false;
      state.error.message = null;
    },
    [newOfferSend.fulfilled]: (state, action) => {
      message.success({
        content: 'Заявка успешно отправлена',
        style: {
          marginTop: '17vh'
        }
      })
      state.adding = false;
      state.form = ''
    },
    [newOfferSend.rejected]: (state, action) => {
      // message.error({
      //   content: 'Что-то пошло не так....',
      //   style: {
      //     marginTop: '17vh'
      //   }
      // })
      state.error.message = action.payload;
      state.error.failed = true;
    },
  }
});
export const { changeUploadLocation, changeUnloadLocation, changeDate, changeTerms, changePrice, changeNumber, changeTransportation } = newOfferSlice.actions
export default newOfferSlice.reducer;


